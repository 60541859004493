$PointOfSaleChoice-main-color: #0d5356;
$PointOfSaleChoice-background-label-color: linear-gradient(
    180deg,
    #f8af21 0%,
    #fe920d 100%
);

:export {
    mainColor: $PointOfSaleChoice-main-color;
}
