
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner {
    display: flex;
    justify-content: start;
    position: relative;
    align-items: flex-start;
    height: auto;

    min-height: calc(100vh - 58px);

    .form {
        z-index: 5;
        width: 100%;
        padding: 30px 24px;
        max-width: 665px;
        border-radius: 30px;
        min-height: 600px;
        background-color: white;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        position: relative;
        @include lg {
            min-width: 400px;
            width: 60%;
            padding: 40px 40px;
            margin-bottom: 200px;
            margin-top: 93px;
        }
        .step-block:nth-of-type(1) {
            .step-description {
                max-width: 464px;

                margin-left: auto;
                margin-right: auto;
            }
            .form-field > label {
                font-weight: 700;
                @include lg {
                    font-size: 18px;
                    line-height: 27px;
                }
            }
        }

        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                @apply font-bold text-2xl;
                margin: 0 !important;
                @include lg {
                    @apply text-3xl;
                }
            }
        }
        .step:nth-child(1) .form-field:nth-child(1) > label {
            @apply text-base font-bold mb-3 text-center;
            @include lg {
                @apply text-base;
            }
        }
        .step-title {
            text-align: center;
            color: $PointOfSaleChoice-main-color;
            font-weight: 800;

            margin-bottom: 25px !important;
            @include lg {
                font-size: 30px;
                line-height: 45px;
                margin-right: -50px;
                margin-left: -50px;
            }
        }
        .label-as-step-title {
            text-align: center;
            @apply font-bold;
            @apply text-xl;
            color: $PointOfSaleChoice-main-color;

            margin-top: -20px;
            margin-bottom: 25px !important;
            @include lg {
                font-size: 30px !important;
                line-height: 40px !important;
                margin-right: -50px;
                margin-left: -50px;
            }
        }
        .step-description {
            text-align: center;

            @apply text-sm mb-2;
            @include lg {
                font-size: 18px;
                line-height: 27px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            color: #000;
            font-weight: 700;
            svg {
                path {
                    fill: #000;
                }
            }
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 999px !important;

            background: $PointOfSaleChoice-background-label-color;

            transition: all 0.2s ease-in-out;
            @include lg {
                max-width: 450px !important;
            }
        }
        .form-field {
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            @include lg {
                max-width: 450px;
            }
            input,
            select {
                border-radius: 999px !important;
            }
        }
        .radio-field {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            img {
                filter: brightness(0);
            }

            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
                            rgb(51, 51, 51) 0px 0px 0px 2px;
                        font-weight: 600;
                    }
                }
                label {
                    border-radius: 999px;
                    // background-color: $atmmachineprices-pink;
                    background: $PointOfSaleChoice-background-label-color;
                    // inear-gradient(to bottom,#f7dfa5,#f0c14b)

                    min-height: 50px;
                    margin: 3px;
                    color: #000;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    @apply shadow-lg;
                    &:hover {
                        @apply shadow;
                        opacity: 0.8;
                    }
                    > span {
                        font-size: 16px !important;
                        margin-top: 0;
                    }
                    > div {
                        margin-right: 15px;
                        img {
                            width: 25px;
                            height: 25px;
                        }
                        & + span {
                            margin-top: 0;
                            margin-right: inherit;
                            width: auto;
                        }
                    }
                    & > span {
                        margin-right: auto;
                        width: 100%;
                    }
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .checkbox-field {
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        font-weight: 500;
                        background-color: rgba(
                            $pointofsalecost-lynx-screen-blue,
                            0.1
                        );
                    }
                }
                label {
                    border-radius: 3px;
                    border: 1px solid $pointofsalecost-lynx-screen-blue;
                    color: $pointofsalecost-lynx-screen-blue;
                    font-size: 14px;
                    display: flex;
                    align-items: center;

                    @include md {
                        min-height: 50px;
                    }
                }
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
    }
    .image {
        z-index: 2;
        & > div {
            position: absolute !important;
            left: 0;
            bottom: 0;
            height: 300px;
            width: 100vw;
            max-width: unset !important;
            left: 50% !important;
            transform: translateX(-50%) !important;

            @include lg {
                height: 100%;
                top: 0 !important;
                left: 0%;
            }
        }

        img {
            min-height: unset !important;
            min-width: unset !important;
            width: auto !important;
            height: 100% !important;
            max-width: unset !important;
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-color: $PointOfSaleChoice-main-color;
        background-image: url("/assets/images/poschoiceBGOpt.jpg");
        background-size: cover;
        background-repeat: no-repeat;

        z-index: 2;
    }
}
